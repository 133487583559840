import { graphql } from "gatsby"
import TagComponent from "../components/tag"

export default TagComponent

export const query = graphql`
  query($name: String! = "Model", $formatString: String!) {
    allContentfulPerfectPost(sort: { fields: date, order: DESC }, filter: { tags: { eq: $name } }) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        timeToRead
        description {
          id
        }
        tags
      }
    }
  }
`
