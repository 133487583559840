import * as React from "react"
import Tag from "../../../components/tag"
import {postMapper} from "../utils/postMapper";

type Props = {
  data: {
    allPost: any
    [key: string]: any
  }
  pageContext: {
    isCreatedByStatefulCreatePages: boolean
    slug: string
    name: string
    [key: string]: any
  }
  [key: string]: any
}

export default function MinimalBlogCoreTag({ ...props }: Props) {
  const {
    data: { allContentfulPerfectPost },
  } = props

  const modifiedPosts = allContentfulPerfectPost.nodes.map(postMapper);

  return <Tag posts={modifiedPosts} {...props} />
}
